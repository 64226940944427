import React from "react";
import { Link } from "gatsby-plugin-react-i18next";

import ProjectStart1 from "../../assets/images/project-start1.png";
import CircleShape1 from "../../assets/images/shape/circle-shape1.png";
import { useTranslation } from "gatsby-plugin-react-i18next";

const ProjectStartArea = () => {
	const { t } = useTranslation();

	return (
		<div className="project-start-area ptb-100">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-12">
						<div className="project-start-image">
							<img src={ProjectStart1} alt="project" />
						</div>
					</div>

					<div className="col-lg-6 col-md-12">
						<div className="project-start-content">
							<h2>{t("home.CPeh2")}</h2>
							<p>{t("home.CPep")}</p>

							<Link to="/contact" className="default-btn">
								<i className="flaticon-web"></i>
								EN SAVOIR PLUS
								<span></span>
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="circle-shape1">
				<img src={CircleShape1} alt="project" />
			</div>
		</div>
	);
};

export default ProjectStartArea;
