import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import Layout from "../components/App/Layout"
import NavbarThree from "../components/App/NavbarThree"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ProjectStartArea from '../components/SolutionCompanyBranding/ProjectStartArea';
import {Link} from "gatsby";
import StarIcon from "../assets/images/star-icon.png";
import starIcon from "../assets/images/star-icon.png";
import ReactWOW from "react-wow";

const FAQ = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />
            <PageBanner
                pageTitle="FAQ"
                homePageText="Menu principal"
                homePageUrl="/" 
                activePageText="FAQ" 
            />
            <div className="ptb-100">
                <div className="container">
                    <div className="faq-accordion">
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q1. Qu'est-ce qu'un profil structuré CROWLR ?

                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Lors d’un entretien, il est indispensable d’illustrer les compétences que l’on s’attribue et le parcours
                                        que l’on décrit.
                                    </p>
                                    <p>Préparer des exemples issus de ses expériences pro est un point capital de votre
                                        profil pour rassurer les professionnels des ressources humaines ou du line manager qui va visualiser
                                        votre profil.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q2. Qu'est-ce qu'un biais cognitif et comment ces biais peuvent influencer le recruteur ?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Le biais cognitif (distorsions involontaires de la réalité) nous pousse à porter un jugement qui peut biaiser une décision.
                                        Les biais influencent nos choix. Ils peuvent entrainer des choix trop rapides,
                                        le cerveau prend des raccourcis, qui conduit à des erreurs de perception, d’évaluation.
                                        Les biais nous empêchent de rester objectifs.</p>
                                    <p>Le processus de recrutement permet d’évaluer des candidats, différentes approches existent mais certaines permettent
                                        d’augmenter considérablement le taux de prédictibilité de réussite du candidat dans son futur poste.
                                        Définir des critères avant l’entretien est un moyen d'évaluer tous les candidats de manière équitable.
                                        Cela permet d’être objectif et limite les biais cognitifs.</p>

                                    <p>
                                        <br>
                                        </br>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="about-content">
                                                <div className="content">
                                                    <div className="banner-wrapper-content">
                                                    <span className="sub-title">
                                                     <img src={starIcon} alt="banner" />
                                                     Plus d'information ?
                                                     </span>
                                                        <ReactWOW delay='.1s' animation='fadeInRight'>
                                                        <div className="btn-box">

                                                            <Link to="https://mozaikrh.com/11-biais-cognitifs-a-connaitre-pour-mieux-recruter/#:~:text=Les%20biais%20cognitifs%20sont%20une,occultant%20souvent%20une%20r%C3%A9flexion%20rationnelle.&text=Les%20biais%20cognitifs%20influencent%20donc,nous%20emp%C3%AAchent%20de%20rester%20objectifs" className="default-btn">
                                                                <i className="flaticon-tick"></i>
                                                                MozaikRH
                                                                <span></span>
                                                            </Link>

                                                            <Link to="https://lecoledurecrutement.fr/comment-mettre-en-place-les-entretiens-structures/#:~:text=Le%20but%20est%20de%20faire%20redescendre%20la%20pression%20du%20c%C3%B4t%C3%A9,se%20font%20de%20cette%20fa%C3%A7on" className="default-btn">
                                                                <i className="flaticon-tick"></i>
                                                                L’école du recrutement
                                                                <span></span>
                                                            </Link>

                                                        </div>

                                                    </ReactWOW>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p>

                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q3. Pourquoi un profil CROWLR anonyme ?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>A la différence des profils mainstream de type LinkedIn, votre profil CROWLR est « par défaut privé ».
                                        Il n’a pas vocation à générer de trafic, de revenus, ne contribue pas à rediriger du contenu promotionnel, il ne vous expose pas.
                                    </p>
                                    <p>La version de CROWLR "Profil Anonyme" est une solution d’aide à la décision pour vous permettre de faire un
                                        point sur votre activité et parcours professionnel. Nous vous guidons au mieux en fonction de vos
                                        compétences, acquises ou nécessaires et, selon vos recherches. Nous confrontons votre profil à d’autres,
                                        nous vous proposons des profils avec un parcours similaires pour vous assister et vous guider.
                                        Rien de mystérieux, un état des lieux associé à vos centres d’intérêt que nous améliorons
                                        semaines après semaines.
                                    </p>

                                    <ul>Un profil CROWLR anonyme ne vous engage en rien, si ce n’est à mettre en relief vos compétences, vos acquis et parcours par rapport à d’autres profils,
                                        pour une industrie, une fonction et les autres éléments contextuels que vous jugez importants.</ul>

                                    <p>Notre solution peut soit très simplement vous accompagner, pour préparer vos entretiens internes d'évaluation périodiques et/ou professionnel (imposé par le Code du travail tous les deux ans) ;
                                        soit vous souhaitez vous préparer dans le cadre d’un entretien d’embauche.
                                    </p>

                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q4. Pourquoi un profil CROWLR public ?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Votre décision de rendre votre profil CROWLR public (temporairement ou pas) est dans la majorité
                                        des cas motivés par la décision de postuler à une offre d'emploi, en interne ou en externe.
                                        Néanmoins, vous pouvez rester anonyme et ne rendre votre profil visible que pour certains utilisateurs
                                        professionnels qui en auraient fait la demande.</p>
                                            <p>La version de CROWLR "Profil Public" est une solution de Personal Branding qui n'existe que
                                                pour vous aider à capter l'attention des professionnels RH ou de Responsables fonctionnels.
                                                Avec un profil public vous communiquez distinctement aux recruteurs votre engagement pour
                                                une meilleure préparation aux entretiens et en partageant votre Benchmark CROWLR vous
                                                accédez systématiquement aux Viviers fonctionnels des compagnies sélectionnées.</p>
                                            <ul>Pour les professionnels des RH ou les Responsables fonctionnels qui recrutent ou à
                                                minima pré-qualifient sur la base de profils CROWLR, votre profil public comme le profil
                                                anonyme donne du crédit à votre parcours, comme à votre potentiel de réalisation,
                                                l'anonymat en moins lors de la première identification des meilleures candidatures.</ul>
                                            <p>La logique métier sous-jacente de CROWLR qui croise vos déclarations avec celles de vos pairs,
                                                est l'élément clé de formalisme qui permet aux entreprises de s'engager avec nous pour
                                                vous proposer une solution unique.
                                            </p>
                                    </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
Q5. Quelle est l’utilité de renseigner des pairs, des collègues dans mon profil CROWLR ?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p> CROWLR est un réseau professionnel, privé et sur invitation.</p>
                                    <p>Saisir précisément le contexte d’une situation professionnelle passée, ou se projeter dans une responsabilité est étroitement lié à une organisation hiérarchique, humaine, à une dynamique entre collègues ou pairs. CROWLR se distingue au travers de cette cartographie des relations professionnelles, qui rassure les recruteurs et donne du crédit à votre profil professionnel.</p>
                                    <ul>Par exemple, renseigner le rôle de ses pairs dans le cadre d’une mission est important pour :
                                        <li>Soit inviter un responsable hiérarchique direct ou un membre de l’équipe a confirmé votre rôle lors de la mission</li>
                                        <li>soit pour garder en mémoire les responsabilités des membres de l’équipe, la vôtre y compris</li>
                                        <li>ou bien pour inviter une relation professionnelle avec qui vous auriez connecté au cours d’un webinaire</li>
                                    </ul>
                                    <p>Dans les 3 cas, vous avez conscience qu’il s’agit d’ajouter du crédit aux événements que
                                        vous documentez. Décrire vos interactions professionnelles est un élément très important qui
                                        vous définit lors d’un entretien : votre interlocuteur se souviendra de vous à l’image de la
                                        représentation que vous en aurez donnée.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>

            <ProjectStartArea />

            <Footer />
        </Layout>
    );
}

export default FAQ;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;